import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function EveryMorning({ data }) {
  return (
    <Layout>
      <Helmet title="Every Morning | Sugar Ray" />

      <h1>Every Morning</h1>

      <h2>Sugar Ray</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 112</h4>

      <p>Non-ASCAP</p>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Chorus>
          <p>Every Morning there's a halo hanging from the corner</p>
          <p>Of my girlfriend's four post bed</p>
          <p>I know it's not mine but I see if I can use it</p>
          <p>For a weekend or a one night stand</p>
        </Chorus>

        <Verse>
          <p>Couldn't understand ... how to work it out</p>
          <p>Once again as predicted, left my broken heart open</p>
          <p>And you ripped it out</p>
        </Verse>

        <PreChorus>
          <p>Something's got me reeling</p>
          <p>Stopped me from believing</p>
          <p>Turned me around again</p>
          <p>Said that we can do it</p>
          <p>You know I wanna do it again</p>
        </PreChorus>

        <Verse>
          <p>Sugar Ray said</p>
          <p>Ooh Ooh / Every Morning</p>
          <p>Ooh Ooh / Shut the door baby don't say a word</p>
          <p>Ooh Ooh / Every Morning</p>
          <p>Ooh Ooh / Shut the door baby Shut the door baby</p>
        </Verse>

        <Chorus>
          <p>Every Morning there's a heartache hanging from the corner</p>
          <p>Of my girlfriend's four post bed</p>
          <p>I know it's not mine and I know she thinks she loves me</p>
          <p>But I never can believe what she said</p>
        </Chorus>

        <PreChorus>
          <p>Something so deceiving</p>
          <p>When you stop believing</p>
          <p>Turn me around again</p>
          <p>Said we couldn't do it</p>
          <p>You know I wanna do it again</p>
        </PreChorus>

        <Verse>
          <p>Sugar Ray said</p>
          <p>Ooh Ooh / Every Morning</p>
          <p>Ooh Ooh / Shut the door baby don't say a word</p>
          <p>Ooh Ooh / Every Morning</p>
          <p>Ooh Ooh / Shut the door baby Shut the door baby</p>
        </Verse>

        <Bridge>
          <p>
            <Ch>C</Ch>She always rights the <Ch>D</Ch>wrongs
          </p>
          <p>
            <Ch>C</Ch>For me... ba<Ch>Em</Ch>by
          </p>
          <p>
            <Ch>C</Ch>She always rights the <Ch>D</Ch>wrongs
          </p>
          <p>For me</p>
        </Bridge>

        <Chorus>
          <p>Every Morning there's a halo hanging from the corner</p>
          <p>Of my girlfriend's four post bed</p>
          <p>I know it's not mine but I see if I can use it</p>
          <p>For a weekend or a one night stand</p>
        </Chorus>

        <Verse>
          <p>(whistle)</p>
          <p>Every Morning</p>
          <p>Every Morning when I wake up</p>
          <p>Every Morning</p>
          <p>Every Morning when I wake up</p>
        </Verse>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "every-morning.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;